.rc-slider-rail {
    background: linear-gradient(90deg, rgba(30, 34, 61, 1) 0%, rgba(40, 45, 74, 1) 100%);
  }
  
 .rc-slider-track {
    background: linear-gradient(90deg, rgb(146, 235, 156) 0%, rgb(16, 199, 3) 100%);
  }
  
 .rc-slider-dot {
    border: none;
    border-radius: 1px;
    width: 2px;
    margin-left: -1px;
    background: rgb(40, 74, 55);
  }
  
  .rc-slider-dot-active {
    background: #4acd8d;
  }
  
 .rc-slider-handle {
    background: #4acd8d;
    border: solid 2px rgb(45, 252, 62);
  }
  
.rc-slider-handle:active {
    box-shadow: 0 0 0.4rem 0.6rem rgba(10, 193, 41, 0.2);
  }
  
 .rc-slider-mark-text,
  .rc-slider-mark-text-active {
    color: white;
    opacity: 0.5;
  }
  
.rc-slider-mark-text:hover,
 .rc-slider-mark-text-active:hover {
    opacity: 1;
  }
  
  .rc-slider-tooltip {
    z-index: 5;
  }
  
  .rc-slider-tooltip-inner {
    box-shadow: none;
    padding: 0.465rem 0.8rem;
    background: #303652;
  }
  
  .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    border-top-color: #303652;
  }
  